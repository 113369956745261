<template>
  <div class="section section__company">
    <div class="section__wrapper">
      <div class="title-with-icon title-with-icon_menu mb-5">
        <div class="title-with-icon__icon title-with-icon__icon_back"></div>
        <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
        <div class="title-with-icon__delimeter">/</div>
        <div class="title-with-icon__title">Финансово-хозяйственная деятельность</div>
      </div>
      <div class="container-grid grid-3 grid-md-1 mb-md-10" v-if="blocks" itemprop="volume">
        <template v-for="block in blocks">
          <div v-for="doc in block.documents" :key="doc.id" class="container-grid__item">
            <div class="doc fill-white p-24 container-shadow">
              <div class="doc__content row">
                <div class="col-auto text-center">
                  <div class="ext-wrapper m-0">
                    <img src="/pic/icon-doc.svg"/>

                  </div>
                </div>
                <div class="col">
                  <div class="doc__title">
                    {{ doc.name }}
                  </div>
                </div>
              </div>
              <div class="doc__actions">
                <a :itemprop="doc.itempop ? doc.itempop : 'url'" target="_blank" :href="doc.file" class="btn btn-outline-primary">Скачать</a>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="my-5 d-block d-md-none">
        <a class="history-back" href="/sveden">Вернуться</a>
      </div>

      <div class="section__subsection_blue section__company-nav">
        <div class="section__subsection-wrapper">
          <company-navigation/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyNavigation from "../../components/CompanyNavigation";
import ApiService from "../../../services/api.service";
export default {
  name: "InfoBudget",
  components: {CompanyNavigation},
  data() {
    return {
      blocks: null
    }
  },
  mounted() {
    ApiService.get('infocategories/budget').then(res => {
      this.blocks = res.data.info_blocks
      this.$parent.init()
    })
  }
}
</script>